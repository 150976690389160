<template>
  <div class="details">
      <div class="details-box">
       <div class="details_title">疫情下，西北大学打造第二课堂信息管理系统，助力学生社会实践<br/>从立项到结项，一趟不用跑！</div>
       <div class="details_title22">作者：董鲁皖龙发布时间：2020.11.10</div>
       <div class="details_title22">来源：中国教育新闻网</div>
       <div>
中国教育报—中国教育新闻网讯（记者 董鲁皖龙）日前，西北大学公布了2020年暑期社会实践优秀项目及团队，“毛乌素沙漠金鸡沙及周边实践调研”项目获得了校级暑期社会实践优秀团队、省级暑期社会实践优秀团队两项大奖。       </div>
       <div>
8月的骄阳下，历时10天，西北大学“毛乌素沙漠金鸡沙及周边实践调研”团队7名成员在金鸡沙村实地走访了治沙基地、蔬菜工厂，采访了牛玉琴及附近居民，并亲手在沙漠边缘种下了治沙树，实地体验了沙地绿化的艰辛。实践成果最终形成论文《毛乌素沙漠中金鸡沙的治理模式探析》，以当地治沙模式为样本，对新时期治沙工作中可能存在的问题和隐患，提出了解决方案。       </div>
       <div>
事实上，在7月份的时候，团队负责人西北大学信息科学与技术学院大一学生李玉成对实践活动能不能成行，还毫无把握。       </div>
       <div>
“当时疫情影响仍在，学校没有开学，不能现场招募成员，也无法进行实践项目的讨论、培训等准备工作，我一度以为今年的暑期实践要‘黄’了。”李玉成说，没想到学校及时上线了“第二课堂信息管理系统”，我第一时间在系统上发布实践计划，招募团队成员，上传材料申报，到后来项目获批、经费下达，直至最近的报奖评奖，一趟没跑就完成了实践项目从申报到结项的全过程。
       </div>
       <div>
西北大学团委副书记刘博介绍，受疫情影响，今年大学生的社会实践面临着组队难、培训难、现场调研难等困境。而学校在2019年上线的由天才在线打造的第二课堂信息管理系统，随着线上流程的不断调试完善，在疫情期间发挥了重要作用。
       </div>
       <div>
打开西北大学第二课堂信息管理系统，可以看到所有实践项目列表，包括项目简介、申报日历、项目日志，以及学生的点赞和评论等。表现形式上，不仅图文并茂，还有视频短片、现场访谈等。
</div>
       <div>
“第二课堂信息管理系统以‘让数据多跑路，学生少跑路’为目标，学生从项目申报、组建团队、线上培训、线上评审、立项公示、实践活动、总结评优等环节全部采用线上操作，并通过结项、获得院系团委审核和校团委评定；既为学生实践提供了方便，也为学校社会实践工作的统计、管理、培训、监督等提供了便利。”刘博说，尽管受疫情影响，今年暑期仍有500多名学生参与了实践，按照“就近线上组队、线下实施实践”原则形成团队71个，实践范围覆盖全国30省区市，而且跨专业、多学科背景的团队占所有团队数量的75%。整个暑期，第二课堂信息管理系统发布200余条短视频，发放调查问卷2000余份，形成专业调研报告73份。
</div>
       <div style=" margin-bottom: 30px;">
“第二课堂信息管理系统的实施，不仅拓宽了社会实践在学生中的覆盖面，提升了学生的参与度，更为学校的社会实践工作的推进提供了坚实的基础。”西北大学团委书记寇嘉介绍，下一步，西北大学将着力开展“劳动+”实践育人，推动“劳动与学科专业结合、与职业生涯规划结合、与思想政治教育结合、与创新创业结合、与志愿服务结合”，打造基于信息系统平台搭建并开展覆盖全校本科生的劳动教育实践课程（活动）。
</div>     
      </div>
      <bottom></bottom>
  </div>
</template>

<script>
import bottom from '../components/bottom'
export default {
  name: 'details',
  components: {
    bottom
  },
}
</script>
<style lang="scss" scoped>
.details{
    padding: 50px 30px;
    padding-bottom: 0;
    background: #fff;
    overflow: hidden;
    margin-top: 1px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    z-index: 1000000;
    overflow: auto;
    .details-box{
        width: 1200px;
        margin: 0 auto;
    }
    .details_title22{
        color: #999;
        text-align: center;
    }
}
.details div{
    text-indent: 2em;
    font-size: 16px;
    text-align: left;
    line-height: 30px;
}
.details h3{
    text-align: left;
}
.details_title{
    text-align: center !important;
    font-size: 36px !important;
    margin: 30px 0 !important;
    margin-bottom: 50px !important;
    line-height: 50px  !important;
}
</style>
